<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>花洒员工管理</h5>
				<div class="formgroup-inline">
					<Button label="新增员工" icon="pi pi-plus" class="mr-2" @click="onShowEditStaff" />
				</div>
				<div class="formgroup-inline mt-3">
					<span class="p-float-label mr-3">
						<Dropdown id="eventEnterprise" :options="enterpriseList" v-model="searchParams.enterpriseId"
							optionLabel="name" optionValue="id" @change="onEnterpriseChange('search')" placeholder="所属企业"
							show-clear="true"></Dropdown>
					</span>
					<span class="p-float-label mr-3">
						<Dropdown id="eventField" :options="fieldList" v-model="searchParams.enterprisePartitionId"
							placeholder="所属场地" optionLabel="site_name" optionValue="id" @change="onFieldChange('search')"
							show-clear="true"></Dropdown>
					</span>
					<div class="field">
						<label for="staffName" class="p-sr-only">姓名</label>
						<InputText id="staffName" type="text" v-model="searchParams.staffName" placeholder="姓名" />
					</div>
					<Button label="查询" @click="onSearch()"></Button>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="staffList" :paginator="true" :rows="10" dataKey="id" :rowHover="true"
					filterDisplay="menu" v-model:selection="selectedStaff" responsiveLayout="scroll" selectionMode="single"
					:lazy="true" :totalRecords="totalCount" @page="onPage">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
							</div>
							<div class="col-6 text-right" style="margin-top:0px;">
								总数: {{ totalCount }}
							</div>
						</div>
					</template>
					<template #empty>
						没有员工记录
					</template>
					<template #loading>
						刷新中，请等待...
					</template>

					<Column field="name" header="ID" style="min-width:10rem">
						<template #body="{ data }">
							{{ data.employee_short_id }}
						</template>
					</Column>
					<Column field="name" header="姓名" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.name }}
						</template>
					</Column>

					<Column field="name" header="属地" style="min-width:20rem">
						<template #body="{ data }">
							{{ data.enterprise_name }}->{{ data.enterprise_partition_name }}
						</template>
					</Column>

					<Column field="name" header="身高" style="min-width:6rem">
						<template #body="{ data }">
							{{ data.height }}cm
						</template>
					</Column>

					<Column field="name" header="手机号" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.employee_id }}cm
						</template>
					</Column>

					<Column header="操作" style="min-width:13rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<Button label="删除" class="p-button-danger p-button-text mr-1" @click="delUser(data, $event)"/>
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Dialog :header="dialogTitle" v-model:visible="displayAddDialog" :style="{ width: '20vw' }" :modal="true">
		<div class="field p-fluid mt-2">
			<label for="deviceEnterprise" class="font-bold">属地</label>
			<div class="grid">
				<span class="p-float-label mr-3 mt-2 ml-2">
					<Dropdown id="deviceEnterprise" :options="enterpriseList" v-model="dataInfo.enterpriseId" dataKey="id"
						optionLabel="name" optionValue="id" @change="onEnterpriseChange()" placeholder="所属企业"
						:show-clear="true"></Dropdown>
				</span>
				<span class="p-float-label mr-3 mt-2 ml-2">
					<Dropdown id="cameraField" :options="fieldList" v-model="dataInfo.enterprisePartitionId" dataKey="id"
						placeholder="所属场地" optionLabel="site_name" optionValue="id" @change="onFieldChange()"
						:show-clear="true">
					</Dropdown>
				</span>
			</div>
		</div>
		<div class="field p-fluid mt-2">
			<label for="deviceName" class="font-bold">员工姓名</label>
			<InputText id="deviceName" type="text" class="p-error" v-model="dataInfo.name" />
		</div>
		<div class="field p-fluid mt-2">
			<label for="deviceName" class="font-bold">身高</label>
			<InputText id="deviceName" type="text" class="p-error" v-model="dataInfo.height" />
		</div>

		<div class="field p-fluid mt-2">
			<label for="" class="font-bold">手机号</label>
			<div class="grid">
				<div class="col">
					<InputText id="staffPhone" type="text" class="p-error" v-model="dataInfo.phone"
						aria-describedby="shampoo-help" place />
				</div>
			</div>
			<div class="grid">
				<div class="col">
					<Button label="生成编号" icon="pi pi-box" class="mr-2" @click="onEditStaffPhoneClicked" />
				</div>
				<div class="col">
					<InputText id="lotion" type="text" class="p-error" v-model="dataInfo.genNumber" disabled
						aria-describedby="lotion-help" />
				</div>
			</div>
		</div>

		<template #footer>
			<Button label="取消" icon="pi pi-times" @click="displayAddDialog = false" class="p-button-text" />
			<Button label="确定" icon="pi pi-check" @click="onEditStaffConfirm" autofocus />
		</template>
	</Dialog>
</template>

<script>
import { xlsx_exprot } from "@/utils/xlsx-export";
import { getToken } from '../utils/auth';
import dateUtil from '../utils/dateUtil.js'

export default {
	data() {
		return {
			dateUtil,

			displayAddDialog: false,
			expandedRows: [],

			//---------------------------------------------
			searchParams: {
				enterpriseId: null,
				enterprisePartitionId: null,
				staffName: '',
			},

			totalCount: 0, // 数据总数
			pageIndex: 1, // 当前页数
			pageSize: 10, // 每页条数

			staffList: [],

			dataInfo: {
				enterpriseId: null,
				enterprisePartitionId: null,
				name: '',
				height: 0,
				phone: '',
				genNumber: '',
			},

			dialogTitle: "新增员工",

			enterpriseList: null,
			fieldList: null,
			regionList: null,

			selectedStaff: null,
			staffSearch: '',
		}
	},
	created() {
		this.searchParams.enterpriseId = this.$route.query.enterprise_id;
		this.searchParams.enterprisePartitionId = this.$route.query.enterprise_partition_id;
		if (this.$route.query.enterprise_id != "") {
			this.getFieldList(this.$route.query.enterprise_id);
		}
		this.locaInfo = JSON.parse(window.localStorage.getItem('userinfo'));

		this.getEnterpriseList();
		if (this.locaInfo.type != "ad") {
			this.getFieldList(this.locaInfo.id);
		}
		this.getStaffList();
	},
	mounted() {

	},
	methods: {
		getFormatDatetime(tim) {
			return dateUtil.formatDatetime(new Date(tim));
		},
		errorMessage(msg) {
			this.$toast.add({ severity: 'error', summary: '发生错误', detail: msg, life: 2000 });
		},
		goodMessage() {
			this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
		},
		// 获取企业列表
		getEnterpriseList() {
			this.$http("/api/admin/enterprise/index", {}).then((res) => {
				if (res.code == 200) {
					this.enterpriseList = res.data;
				}
			});
		},

		onEnterpriseChange(type) {
			if (type == "search") {
				this.getFieldList(this.searchParams.enterpriseId);
			} else {
				this.getFieldList(this.dataInfo.enterpriseId);
			}
		},

		// 获取场地列表
		getFieldList(enterprise_id) {
			this.$http("/api/admin/partition/index", { enterprise_id }).then(
				(res) => {
					if (res.code == 200) {
						this.fieldList = res.data.data;
					}
				}
			);
		},
		onFieldChange(type) {
			if (type == "search") {
				this.getRegionList(this.searchParams.enterprisePartitionId);
			} else {
				this.getRegionList(this.dataInfo.enterprisePartitionId);
			}
		},

		onBeforeSendCameraFile(e) {
			e.xhr.setRequestHeader('admintoken', getToken());
		},

		// 获取区域列表
		getRegionList(enterprise_partition_id) {
			this.$http("/api/admin/region/cdindex", {
				enterprise_partition_id: enterprise_partition_id
			}).then((res) => {
				if (res.code == 200) {
					this.regionList = res.data;
				}
			});
		},
		onRegionChange() {
			// this.getPositionList(this.dataInfo.region);
		},

		// 获取位置列表
		getPositionList(top_id) {
			this.$http("/api/admin/region/xjindex", { top_id }).then((res) => {
				if (res.code == 200) {
					this.positionList = res.data;
				}
			});
		},
		onPositionChange() {
			let name = this.positionList.filter((res) => {
				return res.id == this.dataInfo.position;
			})[0].region_name;
			this.dataInfo.camera = name;
		},

		onPage(event) {
			this.pageIndex = event.page + 1;
			this.getStaffList();
		},

		getStaffList() {
			this.$http('/api/admin/bathuser/index', {
				enterprise_id: this.searchParams.enterpriseId,
				enterprise_partition_id: this.searchParams.enterprisePartitionId,
				name: this.searchParams.staffName,
				page: this.pageIndex,
				page_size: this.pageSize
			}).then(res => {
				if (res.code == 200) {
					this.totalCount = res.data.total;

					var _record_data = res.data.data.map(e => {
						let ee = new Object();
						ee = e;
						return ee;
					})

					this.staffList = _record_data;
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 2000 });
				}
			})
		},

		onShowEditStaff(event) {

			this.dataInfo.enterpriseId = null;
			this.dataInfo.enterprisePartitionId= null;
			this.dataInfo.name= '';
			this.dataInfo.height= 0;
			this.dataInfo.phone= '';
			this.dataInfo.genNumber= '';
			
			this.displayAddDialog = true;
		},

		
		onEditStaffPhoneClicked() {
			let employee_id = this.dataInfo.phone;
			let enterprise_id = this.dataInfo.enterpriseId;
			let enterprise_partition_id = this.dataInfo.enterprisePartitionId;

			var newReg = /^1\d{10}$/;

			if (!newReg.test(employee_id)) {
				this.$toast.add({ severity: 'error', summary: '手机号不正确', life: 2000 });
				return;
			}

			this.$http('/api/admin/bathuser/short_id', {
				employee_id: employee_id,
				enterprise_id: enterprise_id,
				enterprise_partition_id: enterprise_partition_id
			}).then(res => {
				if (res.code == 200) {
					this.dataInfo.genNumber = res.data;
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 2000 });
				}
			})
		},

		onEditStaffConfirm() {
			let employee_id = this.dataInfo.phone;
			let employee_short_id = this.dataInfo.genNumber;
			let enterprise_id = this.dataInfo.enterpriseId;
			let enterprise_partition_id = this.dataInfo.enterprisePartitionId;

			var newReg = /^1\d{10}$/;

			if (!newReg.test(employee_id)) {
				this.errorMessage('手机号不正确');
				return;
			}

			if (Number.parseInt(this.dataInfo.height) < 120) {
				this.errorMessage('不支持的身高');
				return
			}

			if (this.dataInfo.name === "" || this.dataInfo.name === null) {
				this.errorMessage('请填写姓名');
				return
			}

			if (enterprise_id === "" || !enterprise_id) {
				this.errorMessage('请选择企业');
				return
			}

			if (enterprise_partition_id === "" || !enterprise_partition_id) {
				this.errorMessage('请选择场地');
				return
			}

			this.$http('/api/admin/bathuser/create', {
				employee_id: employee_id,
				employee_short_id: employee_short_id,
				height: this.dataInfo.height,
				enterprise_id: enterprise_id,
				name: this.dataInfo.name,
				enterprise_partition_id: enterprise_partition_id
			}).then(res => {
				if (res.code == 200) {
					this.displayAddDialog = false;
					this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
					this.getStaffList();
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 2000 });
				}
			})
		},

		// 摄像头删除
		delUser(item, event) {
			if (item.currUserId == -1) {
				return;
			}

			this.$confirm.require({
				message: '确定要删除这个用户吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				acceptClass: 'p-button-text',
				rejectClass: 'p-button-primary',
				accept: () => {
					this.$http('/api/admin/bathuser/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
							this.getStaffList();
						} else {
							this.$toast.add({ severity: 'error', summary: res.msg, life: 2000 });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},


		// 导出摄像头
		exportCamera() {
			this.displayLoading = true;

			this.$http("/api/admin/camera/index", {
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? this.searchParams.enterpriseId
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				enterprise_partition_id: this.searchParams.enterprisePartitionId,
				region_id: this.searchParams.regionId,
				name: this.searchParams.personnelId,
			}).then((res) => {
				const arrs = [];
				for (const item of res.data.data) {
					const arr = [
						item.name,
						item.mac,
						item.video_address,
						item.account,
						item.pwd,
						item.enterprise_partition_name,
						item.regionId,
						item.is_push == 0 ? "推送" : "不推送",
					];
					arrs.push(arr);
				}
				xlsx_exprot(
					[
						"摄像头名称",
						"摄像头IP",
						"视频流地址",
						"用户名",
						"密码",
						"所属场地",
						"细分区域",
						"是否推送",
					],
					arrs,
					"摄像头管理"
				);

				this.displayLoading = false;
			});
		},

		exportTemplate() {
			this.displayLoading = true;

			const arrs = [
				[
					"摄像头名称",
					"摄像头IP",
					"所属区域",
					"所属位置",
					"企业名称",
					"所属场地",
					"用户名",
					"密码",
					"视频流地址",
					"是否推送（0推送1不推送）",
				],
			];
			xlsx_exprot(
				[
					"name",
					"mac",
					"region_id",
					"position_id",
					"enterprise_id",
					"enterprise_partition_id",
					"account",
					"pwd",
					"video_address",
					"is_push",
				],
				arrs,
				"摄像头倒入模版"
			);

			this.displayLoading = false;
		},

		onUploadError(event) {
			console.log(event)
		},

		onUploadDone(event) {
			var response = JSON.parse(event.xhr.response);
			if (response.msg != "")
				this.errorMessage(response.msg);
			else
				this.$toast.add({ severity: 'success', summary: '导入成功', life: 2000 });
		},
		onSearch() {
			this.getStaffList();
		},

		// 是否推送
		onPushChange(id) {
			this.$http("/api/admin/camera/enable", {
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? ""
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				id,
			}).then((res) => {
				if (res.code == 200) {
					//this.$message.success(res.data);
					this.$toast.add({ severity: 'success', summary: res.data, life: 2000 });
					this.getStaffList();
				} else {
					this.$toast.add({ severity: 'error', summary: res.data, life: 3000 });
				}
			});
		},

		openEditRules(item, index) {
			let item_name = item.name;
			this.enterprise_camer_id = item.id;
			//console.log(this.selectedStaff)
			console.log(index)

			this.$http("/api/admin/rule/index", {
				enterprise_camer_id: item.id,
			}).then((res) => {
				if (res.code == 200) {
					var _start_time = new Date(res.data.time.split("--")[0]);
					var _end_time = new Date(res.data.time.split("--")[1]);
					this.ruleData = {
						name: item_name,
						start_time: _start_time,
						end_time: _end_time,
						status: res.data.is_push == 0,
						input: res.data.minute,
						inputone: res.data.count,
						inputtwo: res.data.after_minute,
						content: res.data.roles,
					};
				} else {
					this.ruleData = {
						name: item_name,
						start_time: "",
						start_branch: "",
						end_time: "",
						end_branch: "",
						status: "",
						input: "",
						inputone: "",
						inputtwo: "",
						content: "",
					};
				}

				this.displayRules = true;
			});
		},
		gotoEventRecords(data) {
			this.$router.push({
				name: "eventrecords",
				query: { enterprise_camer_id: data.id },
			});
		},
		doAddRule() {
			this.$http("/api/admin/rule/create", {
				time: `${this.ruleData.start_time}--${this.ruleData.end_time}`,
				minute: this.ruleData.input,
				count: this.ruleData.inputone,
				after_minute: this.ruleData.inputtwo,
				is_push: this.ruleData.status ? 0 : 1,
				enterprise_camer_id: this.enterprise_camer_id,
				roles: this.ruleData.content,
			}).then((res) => {
				if (res.code == 200) {
					this.$toast.add({ severity: 'success', summary: '操作成功', life: 1000 });
					this.displayRules = false;
				} else {
					this.$toast.add({ severity: 'error', summary: '操作失败', detail: res.msg, life: 3000 });
				}
			});
		},

		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		// clearFilter1() {
		// 	this.initFilters1();
		// },
		// expandAll() {
		// 	this.expandedRows = this.products.filter(p => p.id);
		// },
		// collapseAll() {
		// 	this.expandedRows = null;
		// },
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeCompany(id) {
			console.print('remove:' + id);
		},
		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	},
}

</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
	font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
	font-weight: bold;
}
</style>
